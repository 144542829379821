@import "src/styles/variables.module.scss";

.icons {
    margin-top: 40px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
}

.iconWrapperPrimary {
    @extend .iconWrapper;
    color: $primary;
}

.iconWrapperBg {
    @extend .iconWrapper;
    color: $bg;
}
