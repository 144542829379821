@import "src/styles/variables.module.scss";

.wrapper {
    text-align: justify;

    p {
        margin: 20px 0;
        font-size: $fontSize - 1px;
    }

    h4 {
        font-weight: 700;
        font-size: $fontSize + 1px;
    }

    h5 {
        font-weight: 700;
        font-size: $fontSize;
    }
}
