@import "src/styles/variables.module.scss";

.description {
    text-align: justify;
}

.icon {
    font-size: 42px;

    svg {
        fill: $primary;
    }
}
