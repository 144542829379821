@import "src/styles/variables.module.scss";

.wrapper {
    padding: 68px 0;
}

.primary {
    background-color: $primary;
    color: $white;

    h2 {
        color: $white;
    }
}

.normal {
    background-color: $bg;
    color: $black;

    h2 {
        color: $primary;
    }
}
