@import "src/styles/variables.module.scss";

.wrapper {
    height: 82vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: $gradientToLeft;

    flex-direction: column;
}

.header {
    color: $black;
    font-size: 52px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    text-transform: lowercase;
}

.small {
    color: $black;
    font-size: $fontSize * 1.4;
    font-weight: 400;
    text-align: center !important;
}

.description {
    background: transparent;
}
