@import "src/styles/variables.module.scss";

.footer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    font-size: $fontSize * 0.75;
}

.wrapper {
    display: flex;
    justify-content: flex-end;
}
