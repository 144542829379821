@font-face {
    font-family: "Montserrat";
    src: url("../assets/Montserrat/Montserrat-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Montserrat";
    src: url("../assets/Montserrat/Montserrat-Bold.ttf");
    font-weight: 700;
}
