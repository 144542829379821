@import "src/styles/variables.module.scss";

.contactButton {
    text-align: right;

    button {
        margin-top: 20px;
        width: 100%;
        font-weight: 400;
        text-transform: lowercase;
    }
}

.contact {
    label {
        color: $white;
    }
}
