@import "./fonts";
@import "./variables.module";

body {
    background-image: url("../assets/metryczka.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    font-family: "Montserrat";
    line-height: 2;
    width: 100% !important;
}

h2 {
    color: $primary;
    font-size: $fontSize * 2.4;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: lowercase;
    text-align: center;
}

.ant-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $bg;
}

.ant-layout-content {
    background-color: $bg;
}

.ant-layout {
    background: transparent;
}

.ant-menu {
    background: $bg;
}

.ant-layout-header {
    padding: 0 8px;
    text-transform: lowercase;
}

.ant-btn.ant-btn-primary {
    color: $bg;
}

.ant-layout-footer {
    background-color: $dark-bg;
    color: $bg;
}
