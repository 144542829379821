@import "src/styles/variables.module.scss";

.gallery {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;

    @media screen and (max-width: $md) {
        flex-direction: column;
    }
}

.image {
    width: 46%;

    @media screen and (max-width: $md) {
        width: 100%;
    }
}

.text {
    text-align: center;
}
