@import "src/styles/variables.module.scss";

.headerContentWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.link {
    margin: 0 10px;
}

.logo {
    height: 42px;
    width: auto;
}

.menuWrapper {
    width: calc(100% - 280px);
    max-width: calc(100% - 280px);
}
