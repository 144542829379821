$bg: #f0f2f5;
$dark-bg: darken($bg, 80);
$black: darken($bg, 80);
$white: $bg;
$primary: #56ac46;
$secondary: #ec2028;
$shadow: rgba(86, 172, 70, 0.7);
$grey: #404040;

$fontSize: 17px;

$gradientToLeft: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(256, 256, 256, 0.9) 100%);

$lg: 992px;
$md: 768px;
