@import "src/styles/variables.module.scss";

.gallery {
    background-image: url("../../assets/metryczka.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $grey;
    min-height: 46vh;
    width: 100%;
}
